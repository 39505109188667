@import "./Variables.scss";
.btn-outline-primary,
.btn-primary {
  height: 3.25em;
  width: 8.125em;
  border-radius: 6px;
  font-weight: 500;
}

a.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  color: $white-color;
  line-height: 2.4em;
  font-weight: 500;
  height: 3.25em;
  font-size: 1em;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $primary-color;
    color: $primary-color;
  }
}

a.btn-outline-primary {
  border-color: $primary-color;
  color: $primary-color;
  line-height: 2.25em;
  font-weight: 600;
  height: 3.25em;
  font-size: 1em;
  &:hover,
  &:active {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $white-color;
  }
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: $white-color;
  background-color: $primary-color;
  border-color: $primary-color;
}

