@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@import "./component/sass/Variables.scss";
@import "./component/sass/CustomButtons.scss";
@import "aos/dist/aos.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-size: 16px;
  font-family: $font-family;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

h3 {
  &.h3 {
    font-size: 0.875em;
    font-weight: bold;
    color: $primary-color;
    text-transform: uppercase;
  }
}

h2 {
  font-size: 2.063em;
  font-weight: bold;
  line-height: 45px;
  margin-bottom: 0.5em;
}

p {
  color: $grey-color;
  font-size: 1em;
  line-height: 26px;
}

.navbar {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  .navbar-nav {
    .nav-link {
      font-size: 0.9em;
      padding: 0 1.3em;
      font-weight: 600;
      color: $black-color;
      &:hover {
        color: $primary-color;
      }
    }
  }
}

/* Banner Section */
.bannerSection {
  height: 800px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  h1 {
    font-size: 3.75em;
    font-weight: bold;
    span {
      color: $primary-color;
    }
  }
  p {
    color: $grey-color;
    margin: 1.4em 0 1.7em;
  }

  .ban_img {
    height: 530px;
    width: 100%;
    object-fit: contain;
  }
}

@media (min-width: 991px) {
  .cImg {
    height: 550px;
  }
}

.appComBox {
  background-color: $primary-color;
  padding: 0.6em 1em;
  border-radius: 10px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6em;
  p {
    color: $white-color;
    margin: 0;
  }
  .btn {
    background-color: $white-color;
    border-color: $white-color;
    color: $primary-color;
    &:hover {
      background-color: $black-color;
      border-color: $black-color;
      color: $white-color;
    }
  }
}
.socialLogin {
  display: flex;
  flex-direction: row;
  gap: 1em;
  img {
    width: 196px;
    transition: all 0.3s ease-in-out;
  }
  a {
    &:hover {
      img {
        transform: scale(1.03);
      }
    }
  }
}
/* Register Section */
.registerSection {
  padding: 4em 0;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17, 24, 39, 0.8);
    width: 100%;
    height: 100%;
  }
  .container {
    position: relative;
  }
  h2 {
    text-align: center;
    color: $white-color;
    font-size: 2.125em;
    font-weight: bold;
  }
  p {
    text-align: center;
    color: rgba($color: $white-color, $alpha: 1);
    margin: 1.7em 0;
  }
  .sendbTn {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
}

.sendbTn {
  position: relative;

  .form-control {
    height: 64px;
    border-radius: 12px;
    border-color: transparent;
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    background-color: rgba($color: $white-color, $alpha: 0.2);
    color: rgba($color: $white-color, $alpha: 1);
    &::placeholder {
      color: rgba($color: $white-color, $alpha: 1);
    }
    padding-left: 1.5em;
    padding-right: 4.5em;
    &:focus {
      box-shadow: none;
    }
  }
  .btn {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 54px;
    height: 54px;
    border-radius: 12px;
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    background-color: $primary-color;
    border-color: $primary-color;
    svg {
      font-size: 1.4em;
    }
    &:hover {
      background-color: $white-color;
      border-color: $primary-color;
      color: $primary-color;
    }
  }
}

/* About Us */
.commonpad {
  padding: 5em 0;
}

.aboutusHeading {
  font-size: 18px;
  font-family: $font-family;
  color: #000000c7;
}

/* howitwork */
.howitwork {
  // background-color: #f8f8f8;
  &__body {
    box-shadow: 0 0 20px 0 rgba($color: #000000, $alpha: 0.05);
    background-color: rgba($color: $white-color, $alpha: 1);
    padding: 1.5em;
    border-radius: 1000px;
    height: 335px;
    width: 100%;
    max-width: 335px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    transition: all ease-in-out 0.3s;
    h3 {
      font-size: 1.375em;
      font-weight: bold;
      line-height: 36px;
      margin-bottom: 0.5em;
    }
    .numberBox {
      position: absolute;
      top: 0;
      right: 10px;
      border: 1px dashed $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5em;
      border-radius: 100px;
      h4 {
        background-color: $primary-color;
        width: 70px;
        height: 70px;
        border-radius: 100px;
        color: rgba($color: $white-color, $alpha: 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-weight: 600;
        font-size: 1.875em;
      }
    }
    p {
      margin: 0;
    }
    .arrowPos {
      position: absolute;
      right: -84px;
    }
    &:hover {
      background-color: $primary-color;
      h3,
      p {
        color: $white-color;
      }
      .numberBox {
        border-color: $black-color;
        h4 {
          background-color: $black-color;
        }
      }
    }
  }
}

.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.icon-style {
  background-color: $primary-color;
  border: 2px solid $white-color;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: $white-color;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: $white-color;
  color: $primary-color;
  border: 2px solid $primary-color;
}

.downloadApp {
  .socialLogin {
    margin-top: 2.2em;
    img {
      width: 150px;
    }
  }
}

/* Footer */
.footer {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding: 4em 0 0;
  .flogo {
    width: 130px;
  }
  .foot_links {
    margin: 2.4em 0;
    ul {
      list-style: none;
      padding-left: 0;
      flex-direction: row;
      display: flex;
      justify-content: center;
      margin: 0;
      li {
        position: relative;
        &:not(:last-child)::after {
          content: "";
          position: absolute;
          height: 25px;
          width: 1px;
          background-color: rgba($color: $white-color, $alpha: 0.2);
        }
        a {
          color: $white-color;
          text-decoration: none;
          font-size: 1em;
          font-weight: 400;
          transition: all ease 0.3s;
          padding: 0 1.5em;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
  .social_links {
    padding-bottom: 3em;
    flex-direction: row;
    display: flex;
    justify-content: center;
    gap: 1em;
    img {
      transition: all 0.3s ease-in-out;
    }
    a {
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  .copyright {
    border-top: 1px solid rgba($color: $white-color, $alpha: 0.2);
    p {
      padding: 1em 0;
      color: rgba($color: $white-color, $alpha: 1);
      font-weight: 500;
    }
  }
}

.imgBox {
  img {
    height: 80px;
    width: 80px;
    object-fit: contain;
  }
}

.radiusHardwork {
  .howitwork__body {
    border-radius: 50px;
    .numberBox {
      top: 5px;
      right: 6px;
    }
  }
}

/* Media Quary */
@import "./component/sass/MediaQuary.scss";
