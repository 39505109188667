@media only screen and (min-width: 1700px) {
}

@media only screen and (min-width: 1400px) {
}

@media only screen and (max-width: 1399px) {
  .howitwork {
    &__body {
      .arrowPos {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1199px) {
  .howitwork {
    &__body {
      height: 295px;
      .numberBox {
        top: -25px;
        right: 10px;
      }
      h3 {
        font-size: 1.175em;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .navbar {
    .navbar-nav {
      padding: 1em 0;
      .nav-link {
        margin-bottom: 1.5em;
        width: 100%;
        font-size: 1em;
        padding: 0 0.5em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .howitwork {
    &__body {
      margin-bottom: 2.5em;
      height: 335px;
      .numberBox {
        top: -15px;
        right: 10px;
      }
      h3 {
        font-size: 1.175em;
      }
      &:last-child {
        margin-bottom: 0;
        margin-top: 2.5em;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  section {
    overflow: hidden;
  }

  h2 {
    font-size: 1.5em;
    line-height: 35px;
  }
  .bannerSection {
    h1 {
      font-size: 3.45em;
    }
  }

  .commonpad {
    padding: 3em 0;
  }

  .downloadApp {
    .socialLogin {
      margin-bottom: 3em;
    }
  }

  .aboutUs {
    h3 {
      padding-top: 2em;
    }
  }
  .navbar {
    background-color: $white-color;

    z-index: 2;
  }
  .bannerSection {
    height: initial;
    padding: 7em 0 0em;
    .ban_img {
      height: 450px;
    }
  }
  .socialLogin {
    img {
      width: 130px;
    }
  }
  .footer {
    .foot_links {
      ul {
        flex-direction: column;
        li {
          padding-bottom: 1.3em;
          &::after {
            display: none;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    .copyright {
      p {
        font-size: 0.875em;
      }
    }
  }
}

@media only screen and (max-width: 565px) {
}
